<template>
	<view-component
	v-if="view == 'estados-de-pedido'"
	model_name="order_status"></view-component>
</template>
<script>
export default {
	components: {
		ViewComponent: () => import('@/common-vue/components/view/Index')
	}
}
</script>